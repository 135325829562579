<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">

      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>

            <router-link :to="{ name: 'company.education.catalog.list' }">Katalog Eğitimleri</router-link>
            <li>Katalog Eğitimi Ekle</li>
          </ul>
        </nav>
      </div>

      <template v-if="errorMessages.length > 0">
        <div class="card">
          <div v-for="error in errorMessages" v-if="errorMessages" class="uk-alert-danger" uk-alert>
            <a class="uk-alert-close" uk-close></a>
            <p style="color:red;">
              <i class="uil-exclamation-triangle"></i> {{ error }}
            </p>
          </div>
        </div>
      </template>

      <template v-if="successMessage">
        <div class="card">
          <div class="uk-alert-success" uk-alert>
            <a class="uk-alert-close" uk-close></a>
            <p style="color:green;">
              <i class="uil-exclamation-triangle"></i> {{ $t('general.update_success') }}
            </p>
          </div>
        </div>
      </template>

      <div class="section-header mb-lg-2 border-0 uk-flex-middle">
        <div class="section-header-left">
          <h2 class="uk-heading-line text-left"><span>Katalog Eğitimi Ekle</span></h2>
        </div>
      </div>

      <div class="card">

        <ul class="uk-child-width-expand uk-tab stepper-buttons"
          uk-switcher="connect: #course-edit-tab ; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
          <li class="uk-active">
            <a class="p-4" href="#" aria-expanded="true"><i class="icon-feather-flag"></i>
              <b>{{ this.$t('messages.login') }}</b></a>
          </li>
          <li class="">
            <a class="p-4" href="#" aria-expanded="false"><i class="uil-book-open"></i> <b>İçerik</b></a>
          </li>
          <li>
            <a class="p-4" href="#" aria-expanded="false"><i class="uil-cog"></i> <b>Özellikler</b></a>
          </li>
          <li>
            <a class="p-4" href="#" aria-expanded="false"><i class="uil-invoice"></i> <b>Sonuç</b></a>
          </li>
        </ul>

        <div class="card-body">
          <ul class="uk-switcher uk-margin" id="course-edit-tab" style="touch-action: pan-y pinch-zoom;">
            <!--tab: özellikler-->
            <li class="uk-active  " style="">
              <div class="row">
                <div class="col-xl-9 m-auto">
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="course_title">Eğitim
                      Başlığı<span class="required">*</span></label>
                    <div class="col-md-9">
                      <input type="text" class="form-control" id="course_title" name="title"
                        placeholder="Eğitim başlığı" v-model="form.title" required="">
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="course_code">Eğitim Kodu<span
                        class="required">*</span></label>
                    <div class="col-md-3">
                      <toggle-button :width="100" :value="true" v-model="form.code_is_auto" color="#ae71ff"
                        :labels="{ unchecked: 'El İle', checked: 'Otomatik' }" />
                    </div>
                    <div class="col-md-6" v-if="form.code_is_auto === false">
                      <input type="text" class="form-control" id="course_code" name="course_code" placeholder=""
                        v-model="form.code" required="">
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="description">Açıklama<span
                        class="required">*</span></label>
                    <div class="col-md-9">
                      <textarea name="description" id="description" v-model="form.description"
                        class="form-control"></textarea>
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="category">Kategori <span
                        class="required">*</span></label>
                    <div class="col-md-7">
                      <select class="selectpicker1" name="content_category_id" v-model="form.category_id">
                        <option disabled>Kategori Seçiniz</option>
                        <option v-for="item in educationTypes" :key="item.id" :value="item.id">
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <a class="btn btn-sm btn-secondary btn-icon-label uk-first-column"
                        @click="opencategoryEditModal()">
                        <span class="btn-inner--icon"><i class="uil-edit"></i></span>
                        <span class="btn-inner--text">Düzenle</span>
                      </a>
                    </div>

                    <div id="modal-category-edit" uk-modal>
                      <div class="uk-modal-dialog uk-modal-body">
                        <h2 class="uk-modal-title">Kategorileri Düzenle</h2>
                        <div class="table-responsive">
                          <table class="table align-items-center">
                            <thead>
                              <tr>
                                <th scope="col">Kategori Adı</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody class="list">
                              <tr>
                                <td>
                                  <input type="text" v-model="newCategory" placeholder="Yeni Kategori Başlığı">
                                </td>
                                <td class="text-right">
                                  <div class="actions ml-3">
                                    <button class="btn btn-sm btn-secondary btn-icon-label uk-first-column"
                                      @click="addCategory()">
                                      <span class="btn-inner--icon"><i class="uil-plus"></i></span>
                                      <span class="btn-inner--text">Ekle</span>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                              <tr v-for="(element, index) in educationTypes">
                                <td>
                                  <input type="text" placeholder="Yeni Kategori Başlığı" v-model="element.name">
                                </td>
                                <td class="text-right">
                                  <!-- Actions -->
                                  <div class="actions ml-3">
                                    <a @click="saveCategory(element, index)" href="javascript:void(0)"
                                      class="btn btn-icon btn-hover btn-sm btn-circle ">
                                      <i class="uil-check" :class="{ 'text-success': savedUpdateIndex === index }" />
                                    </a>
                                    <a @click="deleteCategory(element, index)" href="javascript:void(0)"
                                      class="btn btn-icon btn-hover btn-sm btn-circle">
                                      <i class="uil-trash text-danger"></i>
                                    </a>
                                  </div>

                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <p class="uk-text-right">
                          <button class="uk-button uk-button-default uk-modal-close mr-2" type="button"> {{
                            $t("general.close") }}</button>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="short_description">{{
                      $t('general.training_types') }} <span class="required">*</span></label>
                    <div class="col-md-9">
                      <select class="selectpicker1" name="content_category_id" v-model="form.training_kind_id">
                        <option disabled>Eğitim Çeşiti Seçiniz</option>
                        <option v-for="item in formattedTrainingKind" :key="item.id" :value="item.id">
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label">Kapak Görseli <span class="required">*</span></label>
                    <div class="col-md-9">
                      <div class="uploadOuter">
                        <label for="questionFileUpload" class="btn btn-info"><i class="uil-upload"></i>{{
                          $t('general.upload') }}</label>
                        <strong>{{ $t('general.or') }}</strong>
                        <span class="dragBox">
                          <i class="icon-feather-file-plus"></i>
                          {{ $t('general.doc_file_drag_drop') }}
                          <input accept="image/*" type="file" @change="dragNdrop($event)" id="questionFileUpload">
                        </span>
                      </div>
                      <div id="preview" class="filePreview" v-html="preview"></div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="hashtags">Kategori Etiketleri</label>
                    <select class="js-example-basic-multiple" multiple="multiple">
                      <option v-for="item in formattedHashTag" :key="item.id" :value="item.id">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="uk-card-footer float-right pb-0 pr-0">
                <button @click="validateAndSwitch(1)" class="uk-button uk-button-primary small" type="submit"><i
                    class="icon-feather-arrow-right"></i>{{ this.$t('general.save_and_continue') }}</button>
              </div>
            </li>
            <!--tab: icerik olustur-->
            <li class="" style="">
              <ContentArea @getData="getData($event)" @useScormScore="useScormScore($event)"></ContentArea>

              <div class="uk-card-footer float-right pb-0 pr-0">
                <button @click="switcherChange(0)" class="uk-button uk-button-outline small mr-2" type="submit"><i
                    class="icon-feather-arrow-left"></i>Önceki</button>
                <button @click="validateAndSwitch(2)" class="uk-button uk-button-primary small" type="submit"><i
                    class="icon-feather-arrow-right"></i> {{ this.$t('general.save_and_continue') }}</button>
              </div>
            </li>
            <li>
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group row mb-2">
                      <label class="col-md-3 col-form-label">Fiyat</label>
                      <div class="col-md-3">
                        <input type="number" v-model="form.price" class="form-control" />
                      </div>
                      <div class="col-md-3">
                        <select class="selectpicker1" name="currency">
                          <option selected val="try">Türk Lirası (₺)</option>
                          <option val="usd">Dollar ($)</option>
                          <option val="eur">Euro (€)</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row mb-2">
                      <label class="col-md-3 col-form-label" for="website_keywords">İndirim Mesajı
                        <i class="uil-info-circle" uk-tooltip="title: " title="" aria-expanded="false"></i></label>
                      <div class="col-md-6">
                        <textarea v-model="form.discount_codes" name="sale" id="sale" class="form-control"></textarea>
                      </div>
                    </div>
                    <div class="form-group row mb-2">
                      <div class="col-md-3 col-form-label">
                        <label for="sale_ratio">İndirim Oranı (%)</label>
                      </div>
                      <div class="col-md-6">
                        <input v-model="form.discount_percent" type="number" class="form-control" id="sale_ratio"
                          name="title" placeholder="" required="">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uk-card-footer float-right pb-0 pr-0">
                  <button @click="switcherChange(0)" class="uk-button uk-button-outline small mr-2" type="submit"><i
                      class="icon-feather-arrow-left"></i>Önceki</button>
                  <button @click="validateAndSwitch(3)" class="uk-button uk-button-primary small" type="submit"><i
                      class="icon-feather-arrow-right"></i> {{ this.$t('general.save_and_continue') }}</button>
                </div>
              </div>
            </li>
            <li>
              <div class="row">
                <div class="col-12 my-lg-5">
                  <div class="text-center">
                    <h2 class="mt-0"><i class="icon-feather-check-circle text-success"></i></h2>
                    <h3 class="mt-0">İçerik Hazır !</h3>

                    <div class="col-md-6 offset-md-3">
                      <table class="uk-table uk-table-divider result-table">
                        <tbody>
                          <tr>
                            <td class="text-right">Kapak Görseli:</td>
                            <td class="text-left"><img class="rounded img-fluid" id="summaryImage"
                                src="@/assets/images/no-photo.png" alt=""></td>


                          </tr>
                          <tr>
                            <td class="text-right">{{ this.$t('collectionDetail.content_name') }}:</td>
                            <td class="text-left"><b>{{ form.title }}</b></td>
                          </tr>
                          <tr>
                            <td class="text-right">Kategori Etiketleri: <span class="required">*</span></td>
                            <td class="text-left"><b>{{ hashtagContent }}</b></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="uk-card-footer float-right pb-0 pr-0">
                <button @click="switcherChange(2)" class="uk-button uk-button-outline small mr-2" type="submit"><i
                    class="icon-feather-arrow-left"></i>Önceki</button>
                <button @click="saveForm" class="uk-button uk-button-primary small" type="submit"><i
                    class="icon-feather-arrow-right"></i> {{ $t('general.save') }}</button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>







</template>

<script>
import Vue from "vue";
import store from '@/core/services'
import moment from "moment";
import Swal from "sweetalert2";
import ContentArea from "./ContentAreaAdd";

import { GET_ITEMS as GET_CATEGORIES, ITEMS as CATEGORIES } from "@/core/services/store/REST.module";

import training_kind_module, {
  BASE_URL as TRAINING_KIND_URL,
  GET_ITEMS as GET_TRAINING_KINDS,
  MODULE_NAME as MODULE_TRAINING_KIND,
  ERROR,
  ITEMS as TRAINING_KINDS
} from "@/core/services/store/training_kind.module";

import hashtag_module, {
  BASE_URL as HASHTAG_URL,
  GET_ITEMS as GET_HASHTAGS,
  MODULE_NAME as MODULE_HASHTAG,
  ITEMS as HASHTAGS
} from "@/core/services/store/hashtag.module";

import training_module, {
  BASE_URL as TRAINING_BASE_URL,
  MODULE_NAME as TRAINING_MODULE,
  CREATE_ITEM as CREATE_TRAINING,
  ERROR as TRAINING_ERROR
} from "@/core/services/store/training.module";


const _TRAINING_KIND_MODULE = MODULE_TRAINING_KIND;
const _HASHTAG_MODULE = MODULE_HASHTAG;
const _TRAINING_MODULE = TRAINING_MODULE;
import axios from "axios";
export default {
  name: "CatalogContentAdd",
  components: { ContentArea },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }
    registerStoreModule(_TRAINING_KIND_MODULE, training_kind_module)
    registerStoreModule(_HASHTAG_MODULE, hashtag_module)
    registerStoreModule(_TRAINING_MODULE, training_module)
  },
  data() {
    return {
      newCategory: '',
      company_id: '',
      form: {
        title: '',
        code_is_auto: true,
        code: '',
        description: '',
        category_id: null,
        training_kind_id: null,
        media: '',
        price: 0.00,
        discount_codes: '',
        discount_percent: '',
        hashtags: [],
        contents: null,
      },
      trainingCategoryUrl: 'api/training-type',
      educationTypes: [],
      preview: null,
      errorMessages: [],
      hashtagContent: "",
      successMessage: false,
      use_scorm_score: false,
      savedUpdateIndex:null
    }
  },
  computed: {
    training_kinds: {
      get() {
        return store.getters[_TRAINING_KIND_MODULE + '/' + TRAINING_KINDS]
      },
      set(value) {
      }
    },
    hashtags: {
      get() {
        return store.getters[_HASHTAG_MODULE + '/' + HASHTAGS]
      },
      set(value) {
      }
    },
    formattedTrainingKind() {
      let results = [];
      if (this.training_kinds === null || !("data" in this.training_kinds)) return [];
      this.training_kinds.data.forEach(item => {
        results.push({
          id: item.id,
          value: item.id,
          name: item.name
        });
      });
      return results;
    },
    formattedHashTag() {
      let results = [];
      if (this.hashtags === null || !("data" in this.hashtags)) return [];
      this.hashtags.data.forEach(item => {
        results.push({
          id: item.id,
          value: item.id,
          name: item.name
        });
      });
      return results;
    },
    errors: {
      get() {
        return store.getters[TRAINING_MODULE + '/' + TRAINING_ERROR]
      },
      set(value) {
      }
    },
  },
  methods: {
    opencategoryEditModal() {
      UIkit.modal("#modal-category-edit").show();
    },
    deleteCategory(event, index) {
      axios.delete(`api/training-type/` + event.id + '', {
        headers: this.headers
      }).then((result) => {
        console.log(result);
        if (result) {
          this.educationTypes.splice(index, 1)
        }
      })
    },
    saveCategory(event, index) {
      console.log("event",event)
      axios.put(`api/training-type/` + event.id + '',
        { "name": event.name },
        {
          headers: this.headers
        }).then((result) => {
        this.savedUpdateIndex = index;
      })

      setTimeout(() => {
        this.savedUpdateIndex = null;
      }, 2000);

    },
    addCategory() {
      axios.post(`api/training-type/`,
        { "name": this.newCategory }, {
        headers: this.headers
      }).then((result) => {
        if (result) {
          this.newCategory = '';
          this.getTrainingTypes();
        }
      })
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
    showFieldsFailedError(index, message) {
      this.errorMessages.push(message);
      this.scrollToTop();
      this.switcherChange(index);
      return false;
    },
    validateAndSwitch(index) {

      if (this.isValidFields(index - 1)) this.switcherChange(index);
    },
    switcherChange(index) {
      UIkit.switcher($('.uk-child-width-expand')[0]).show(index);
      $("html, body").animate({ scrollTop: 0 }, "slow");

    },
    isValidFields(fields) {
      let form = this.form;
      this.resetMessages();
      if (fields === 0 || fields === 'ALL') {
        if (form.title.length === 0) {
          return this.showFieldsFailedError(
            0,
            this
              .$t("validation.required_field")
              .replace(":NAME", this.$t('Eğitim başlığı'))
          );
        }

        if (form.code_is_auto) {
          form.code = this.makeCode(10);
        } else {
          if (form.code.length === 0) {
            return this.showFieldsFailedError(
              0,
              this
                .$t("validation.required_field")
                .replace(":NAME", this.$t('Eğitim kodu'))
            );
          }
        }

        if (form.description.length === 0) {
          return this.showFieldsFailedError(
            0,
            this
              .$t("validation.required_field")
              .replace(":NAME", this.$t('Açıklama'))
          );
        }

        if (!form.category_id || form.category_id.length === 0) {
          return this.showFieldsFailedError(
            0,
            this
              .$t("validation.required_field")
              .replace(":NAME", this.$t('Kategori'))
          );

        }

        if (!form.category_id || form.training_kind_id.length === 0) {
          return this.showFieldsFailedError(
            0,
            this
              .$t("validation.required_field")
              .replace(":NAME", this.$t('Eğitim Çeşitleri'))
          );
        }

        if (form.media.length === 0) {
          return this.showFieldsFailedError(
            0,
            this
              .$t("validation.required_field")
              .replace(":NAME", this.$t('Kapak Görseli'))
          );
        }
        // . if (form.hashtags?.length === 0) {
        //   return this.showFieldsFailedError(
        //     0,
        //     this
        //       .$t("validation.required_field")
        //       .replace(":NAME", this.$t('Kategori Etiketleri'))
        //   );
        // }

      } else if (fields === 1 || fields === 'ALL') {

      }
      else if (fields === 2 || fields === 'ALL') {
        /*
        if (!form.price || form.price <= 0) {
          return this.showFieldsFailedError(
            2,
            this
              .$t("validation.required_field")
              .replace(":NAME", this.$t('Fiyat'))
          );
        }
        */
      }
      return true
    },
    dragNdrop(event) {
      let file = event.target.files[0];
      this.form.media = file;

      this.preview = '<div class="file-info">' +
        '<div>Format: <span>' + file.type + '</span></div>' +
        '<div>Doküman Adı: <span>' + file.name + '</span></div>' +
        '<div>Boyut: <span>' + this.bytesToSize(file.size) + '</span></div>' +
        '</div><br>' +
        '<div><img src="' + URL.createObjectURL(file) + '" /></div>';
      $('#summaryImage').attr('src', URL.createObjectURL(file));

    },
    createContent() {
      Swal.fire({
        title: 'İçerik Adı',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Oluştur',
        confirmButtonColor: '#ae71ff',
        cancelButtonText: 'Vazgeç',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          let length = this.form.contents.length;
          this.form.contents.push(
            { id: length + 1, name: result.value, educations: null }
          );
        }
      })
    },
    getData(data) {
      this.form.contents = data;
    },
    useScormScore(data) {
      this.use_scorm_score = data;
    },
    makeCode(length) {
      let result = '';
      let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
          charactersLength));
      }
      return result;
    },
    saveForm() {
      if (!this.isValidFields("ALL")) return false;
      let form = this.form;
      let formData = new FormData();
      formData.append('name', form.title);
      formData.append('code', form.code);
      formData.append('description', form.description);
      formData.append('category_id', form.category_id);
      formData.append('training_kind_id', form.training_kind_id);
      formData.append('price', form.price);
      formData.append('media', form.media);
      formData.append('hashtags', form.hashtags);
      formData.append('discount_codes', form.discount_codes);
      formData.append('discount_percent', form.discount_percent);

      this.createFormDataForList(formData, 'contents', form.contents);
      form.contents.forEach((item, index) => {
        if (item.educations.length > 0) {
          item.educations.forEach((edu, eduindex) => {
            if (edu.media) {
              formData.append('contents[' + index + ']' + '[educations][' + eduindex + '][media]', edu.media);
            }
          });
        }
      });

      store.dispatch(_TRAINING_MODULE + '/' + CREATE_TRAINING, {
        url: TRAINING_BASE_URL,
        contents: formData
      }).then(() => {
        this.formErrors = [];
        if (this.errors) {
          this.errorMessages = [];
          this.errorMessages.push(this.errors);
          this.scrollToTop();
        } else {
          this.scrollToTop();
          this.successMessage = true;
          /* this.$router.push({name: 'company.education.catalog.list'}) */
        }
      });
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    },
    getTrainingTypes() {
      this.$store.dispatch(GET_CATEGORIES, {
        url: this.trainingCategoryUrl,
        filters: {
          'company_by_category': true,
        },
        acceptPromise: true,
      }).then(
        (response) => {
          if (response.status) {
            this.educationTypes = response.data;
            setTimeout(function () {
              $('.selectpicker').selectpicker();
            }, 100)
          }
        }
      );
    }
  },
  mounted() {
    if (!this.CatalogTrainingVisible) {
      this.$router.push({ name: 'company.dashboard' })
    }
    let self = this;
    this.getTrainingTypes();


    store.dispatch(_TRAINING_KIND_MODULE + '/' + GET_TRAINING_KINDS, {
      url: TRAINING_KIND_URL
    });
    store.dispatch(_HASHTAG_MODULE + '/' + GET_HASHTAGS, {
      url: HASHTAG_URL + '/?all=all'
    }).then((response) => {
      if (response?.data) {
        this.company_id = response.data[0].company_id
      }
    });

    setTimeout(function () {

      $('.js-example-basic-multiple').select2({
        tags: true,
        tokenSeparators: [',', ' '],
        minimumInputLength: 3
      }).on('change', function () {
        self.form.hashtags = $(this).select2("val");
      });
      $('.select2-container--default .select2-selection--multiple').height('50px');
      $('.select2-container--default .select2-selection--multiple').css('overflow-y', 'auto');
      $('.select2-container--default .select2-selection--multiple').css('border', '1px solid #e2e5ec');
      $('.select2-container--default .select2-selection--multiple').css('border-radius', '4px');
    }, 500);
  },
  watch: {
    'form.hashtags'(value) {
      if (value) {
        let tags = $('.js-example-basic-multiple').find(':selected');
        let values = "";
        $.each(tags, function (index, val) {
          values += $(this).text() + ", "
        })
        this.hashtagContent = values;
      }
    }
  }
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.dragBox input[type="file"] {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
}

.dragBox {
  width: 250px;
  height: 100px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  font-weight: bold;
  line-height: 95px;
  color: #999;
  border: 2px dashed #ccc;
  display: inline-block;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.uploadOuter strong {
  padding: 0 10px;
}

.uploadOuter {
  text-align: center;
  padding: 20px;
}

#summaryImage {
  height: 200px;
}
.text-success {
  color: #0fa704 !important;
  font-size: 32px !important;
  transition: color 0.5s ease, font-size 0.5s ease;
}
</style>
